import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import Image from './Image'
import classNames from 'classnames';

import './BackgroundVideo.css'

class BackgroundVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false,
      mobileWidth: false
    }
    this.ref = React.createRef()
    this._isMounted = false;
  }
  
  updateDimensions() {
    if(this._isMounted)this.setState({ mobileWidth: window.innerWidth <= 900 })
  }

  handelPlay() {
    this.setState({ playing: true })
    ReactDOM.findDOMNode(this.ref.current).removeEventListener(
      'playing',
      this.handelPlay
    )
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateDimensions()
    window.addEventListener('resize', () => this.updateDimensions())
    ReactDOM.findDOMNode(this.ref.current).addEventListener('playing', e =>
      this.handelPlay(e)
    )
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateDimensions)
  }

  render() {
    const { poster, videoTitle, children, className } = this.props
    return (
      <Fragment>
        <video
          ref={this.ref}
          poster={poster}
          className={classNames( className, `${this.state.playing ? 'playing' : ''} `)}
          playsInline
          autoPlay
          muted
          loop
          preload="auto"
        >
          {children}
        </video>
      </Fragment>
    )
  }
}

export default BackgroundVideo
