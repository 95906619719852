import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import BackgroundVideo from '../BackgroundVideo'
import Image from '../Image'
import {isMobile} from 'react-device-detect'

import {ArrowLeftCircle, ArrowRightCircle} from 'react-feather'

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowLeftCircle className={className} style={{ ...style, display: "block", width: '15px', height: '15px', color: 'rgba(253, 170, 1, 0.75)' }} onClick={onClick}/>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowRightCircle className={className} style={{ ...style, display: "block", width: '15px', height: '15px', color: 'rgba(253, 170, 1, 0.75)' }} onClick={onClick}/>
  );
}

export default class SimpleSlider extends Component {
	constructor(props){
		super(props)

		this.slider = null
	}
	handleSwipeToggle = (e) =>{
		e.stopPropagation();
		if (this.props.active !== undefined )this.props.onToggleChange(!this.props.active)
	}
	renderCarouselItems(item, index){
	    let carouselItems = null;
	      switch (item.accordion) {
	        case 'image':
	          carouselItems =<a onClick={this.handleSwipeToggle} className="slider-item__cnt" key={'slider-image' + item.title+index}><Image  className="slider-item__cnt" src={item.featuredImage} alt='' /><span className="slider-item__caption">{item.title}</span></a>
	          break;
	        case 'video':
	          carouselItems =<a onClick={this.handleSwipeToggle} className="slider-item__cnt" key={'slider-video' + item.title+index} ><BackgroundVideo className="slider-item__cnt"><source src={item.featuredImage} type="video/webm" /></BackgroundVideo><span className="slider-item__caption">{item.title}</span></a>
	          break;
	        default:
	          console.log('default')
	          break
	    }
	     
	    return carouselItems
	}
	componentDidUpdate(prevProps){
		if(prevProps.index !== this.props.index)this.slider.slickGoTo(this.props.index)
	}
  	render() {
  		console.log(this.props.index)
  		let index = this.props.index ? this.props.index : 0;
  		
    	const settings = {
	      	dots: true,
	      	accessibility: true,
	      	fade: true,
	      	infinite: true,
	      	speed: 500,
	      	slidesToShow: 1,
	      	slidesToScroll: 1,
	      	autoplay: true,
	      	autoplaySpeed: 7000,
	      	pauseOnHover: true,
	      	adaptiveHeight: true,
	      	nextArrow: <SampleNextArrow />,
      		prevArrow: <SamplePrevArrow />,
      		initialSlide: index
	    };
	    let visibleChildren = this.props.items.slice(0, 4);
	    return (
	      <Fragment>
	        <Slider {...settings} ref={slider => (this.slider = slider)}>
	        	{visibleChildren.map((item, i) => (
	        		this.renderCarouselItems(item, i)
	        	))}
	        </Slider>
	      </Fragment>
	    );
  }
}