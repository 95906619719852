import React, { Fragment, Component } from 'react'
import _ from 'lodash'

import AccordionInnerContent from './AccordionInnerContent'
import SimpleSlider from '../Carousel/Carousel'

export default class AccordionExtraLayer extends Component{
	constructor(props){
		super(props)
		this.state = {
			activeIndex: 0
		}
	}
	handleMouseEnter = (state)=>{this.setState({activeIndex: state})}
	render(){
		let {posts, category, lang, active, onToggleChange} = this.props;
		return(
			<Fragment>
				<div className='card-body'>
	                <ul className="accordion__content"> 
	                  <AccordionInnerContent posts={posts} category={category} lang={lang} mouseEnter={this.handleMouseEnter}/>
	                </ul>
	                <div className="accordion__media">
	                  <SimpleSlider items={posts} active={active} onToggleChange={onToggleChange} index={this.state.activeIndex}/>
	                </div>           
            	</div>
			</Fragment>
		)
	}
}