import React, {Fragment} from 'react'
import gsap from 'gsap'

import Image from '../Image'
import _ from 'lodash'
import {isMobile} from "react-device-detect";
import {X} from 'react-feather'

import Slider from '../Carousel/Carousel'
import AccordionExtraLayer from './AccordionExtraLayer'

import './Accordion.css'
import '../Carousel/Carousel.css'
import '../Popup.css'

export default class Accordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: -1,
      limit: 4,
      bgImages: [],
      orientation: '',
      offsetUnit: '',
      showPopup: false,
      init: false
    }
    this.accOpen = false;
    this.resize = false;
    this.accordion = null;
    this.accordionItems = [];
    this.accImgTL = gsap.timeline({paused: true})
    this.accImages = []
    this.offset = null;
    this.sizes = null;
    this.mql = null;
  }
  refCallback = element => {
    if (element) {this.accordion = element;}
  }
  toggleAccordionClick = (activeIndex, event) =>{
    if (typeof activeIndex === 'undefined') activeIndex = -1
    if(!this.state.init)this.setState({init: true})
    this.setState({activeIndex: activeIndex})
  }  
  componentDidMount(){
    //console.log(Slider)
    this.mql = window.matchMedia("(orientation: portrait)");
    if (isMobile) {
      if(this.mql.matches || window.orientation === 0){this.setState({offsetUnit: 'height'})}
      if(!this.mql.matches || window.orientation === 90){this.setState({offsetUnit: 'width'})}
     }
    window.addEventListener("resize", this.handleResize)
  }
  handleResize = (e)=>{
    if(!this.resize) this.resize = true;
    if(isMobile){     
      if(!this.mql.matches || window.orientation === 90){
        this.setState({offsetUnit: 'width'})
      } else if(this.mql.matches || window.orientation === 0){
        this.setState({offsetUnit: 'height'})
      }
    }else{
      //Resize Desktop noch herrichten
      this.setState({offsetUnit: 'width'})
    }
  }
  sortByDate = (posts) =>{
    const now = Date.now();
    return _.reverse(_.sortBy(posts, 'date'))
  }
  componentDidUpdate(prevProps, prevState){
    if(prevState.offsetUnit !== this.state.offsetUnit){
      if(prevState.activeIndex !== this.state.activeIndex){ 
        if(this.state.init){
          //resize und tab wurde schon gecklikckt
          if(!this.accOpen){
            this.accordionItems[this.state.activeIndex].classList.add('active');
            this.accordionToggle(true, null);
            this.accOpen = true
          }else{
            if(this.accordionItems[prevState.activeIndex].classList.contains('active'))this.accordionItems[prevState.activeIndex].classList.remove('active');
            this.accordionItems[this.state.activeIndex].classList.add('active');
            this.accordionToggle(true, prevState.activeIndex);
          }
        }
      }else{
        //resize with same tab pen und das soll so bleiben
        if(this.state.init){
          if(this.accOpen){
            this.accordionItems[this.state.activeIndex].classList.add('active');
            this.accordionToggle(true, null);
            this.accOpen = true
          }else{
           if(this.accordionItems[prevState.activeIndex].classList.contains('active'))this.accordionItems[prevState.activeIndex].classList.remove('active');
            this.accordionItems[this.state.activeIndex].classList.add('active');
            this.accordionToggle(true, prevState.activeIndex);
          }
        }
      }
    }else{
      if(prevState.activeIndex !== this.state.activeIndex){ 
        //nicht resized und anderer Tab wurde gecklicked oder das erste mal
        if(this.state.init){
          if(!this.accOpen){
            this.accordionItems[this.state.activeIndex].classList.add('active');
            this.accordionToggle(true, null);
            this.accOpen = true
          }else{
            if(this.accordionItems[prevState.activeIndex].classList.contains('active'))this.accordionItems[prevState.activeIndex].classList.remove('active');
            this.accordionItems[this.state.activeIndex].classList.add('active');
            this.accordionToggle(true, prevState.activeIndex);
          }
        }
      }else{
        //nicht resized und seber Tab wurde gecklicked
        if(this.state.init){
          if(!this.resize){
            if(this.accOpen){              
                if(this.accordionItems[this.state.activeIndex].classList.contains('active'))this.accordionItems[this.state.activeIndex].classList.remove('active');
                this.accordionToggle(false, null);
                this.accOpen = false;
            }else{
              this.accordionItems[this.state.activeIndex].classList.add('active');
              this.accordionToggle(true, null);
              this.accOpen = true;
            }
          }else{
            if(this.accOpen){
              this.accordionToggle(true, null);
              this.accOpen = true;
            }else{             
              this.accordionToggle(false, null);
              this.accOpen = false;
            }
          }         
        }
      }
    } 
  }
  accordionToggle = (isOpen, prevIdx) =>{
    let activeParent = this.accordionItems[this.state.activeIndex], prevParent;   
    let cnt = activeParent.querySelector('.card-body');
    let litm = cnt.querySelectorAll('.list-item')
    let listBdy = cnt.querySelector('.accordion__content');
    let mediaBdy = cnt.querySelector('.accordion__media');
    let listLnk = cnt.querySelector('.acc.taCenter');
    let sizes = this.accordion.getBoundingClientRect(), width;

    if(isOpen){ 
      if(prevIdx !== null) prevParent = this.accordionItems[prevIdx];
      if(isMobile ){ 
        if(this.state.offsetUnit === 'height'){     
          gsap.set(this.accordion,{height: '100%'});
          gsap.to(activeParent, {duration:0.3, 'height': sizes.height, 'width': sizes.width}) 
          gsap.set(cnt, {opacity: 1, display: 'flex'}); 
          gsap.set(listBdy, {width: '100%'});      
          gsap.set(mediaBdy, {display: 'block', width: '100%'});
          gsap.to(mediaBdy, {duration: 1.5,opacity: 1, delay: 1, ease: "power3.inOut"});
          gsap.fromTo(listLnk, {opacity: 0},{duration: 2, opacity: 1, delay: 1.2, ease:"power3.inOut"})
          this.accordion.parentNode.removeAttribute('style');       
        }else if (this.state.offsetUnit === 'width'){ 
          let mediaBdySize;
          if(sizes.width < 888){
            width = sizes.width
            mediaBdySize = '50%'
          }else{
            width = sizes.width - (sizes.width*0.12);
            mediaBdySize = '100%'
          }
          gsap.to(activeParent, {duration:0.3, 'width': width, 'height': sizes.height}); 
          gsap.set(cnt, {opacity: 1, display: 'flex'});
          gsap.set(listBdy, {width: '100%'});
          gsap.set(mediaBdy, {display: 'block', width: mediaBdySize});
          gsap.to(mediaBdy, {duration: 1.5,opacity: 1, delay: 1,ease: "power3.inOut"});
          gsap.fromTo(listLnk, {opacity: 0},{duration: 2, opacity: 1, delay: 1.2, ease:"power3.inOut"})

          // this.accordion.parentNode.style.overflowY = 'hidden'
          // this.accordion.parentNode.style.overflowX = 'auto';
         }
         gsap.fromTo(litm, {opacity: 0},{duration: 1.2, opacity:1, stagger: 0.2, delay: 0.5})
         if(this.resize) this.resize = false;
      }else{
        gsap.set(this.accordion,{height: '100%'});
        let mediaBdySize;
        if(sizes.width < 888){
          width = sizes.width
          mediaBdySize = '40%'
        }else{
          width = sizes.width - (sizes.width*0.12);
          mediaBdySize = null
        }
        gsap.to(activeParent, {duration:0.3, 'width': width, 'height': sizes.height});
        gsap.set(mediaBdy, {display: 'block'});
        gsap.to(mediaBdy, {duration: 1.5,opacity: 1, delay: 0.7,ease: "power3.inOut"});
        gsap.fromTo(listLnk, {opacity: 0},{duration: 2, opacity: 1, delay: 1.2, ease:"power3.inOut"})
        if(!this.resize){
           gsap.fromTo(litm, {opacity: 0, rotationX:-120,},{duration: 0.6, opacity:1, rotationX:0, force3D:true,transformOrigin:"top center -150", stagger: 0.2, delay: 0.1})
        }
        if(this.resize) this.resize = false;        
      }    
      if(prevIdx !== null){
        prevParent.removeAttribute('style');
        prevParent.querySelector('.card-body').removeAttribute('style');
        prevParent.querySelector('.accordion__media').removeAttribute('style');
      }  
    }else{
      activeParent.removeAttribute('style');
      activeParent.querySelector('.card-body').removeAttribute('style')
      activeParent.querySelector('.accordion__media').removeAttribute('style')
    }
  }
  renderBgImages(images, i){
    return <Image background src={images[i].image} alt={images[i].alt} ref={div => this.accImages[i] = div}/>
  }
  sortItems(categories, work){
    let featuredCategories = _.map(categories, (cat => {return cat.title})),
    items = {};
    _.forEach(featuredCategories, (el) =>{
        items[el] = []
      _.forEach(work, (wrk, i)=>{
        if(!_.includes(wrk.category, el)) return 
        items[el].push(wrk);            
      })
    })
    return items
  }
  togglePopup = (e)=> {
    this.setState({showPopup: !this.state.showPopup})
  }
  render() {   
     
    let { work, categories, images, locale } = this.props;
    let accItems = this.sortItems(categories, work)
    return (
      <Fragment>
        <div className='accordion' ref={this.refCallback}>       
          {categories.map((category, i) => (
          <section className='accordion--item card' key={`${i}-catitem`} ref={div => this.accordionItems[i] = div}>
            {images && this.renderBgImages(images, i)}
            <button className="card-header" onClick={(e) => this.toggleAccordionClick(i,e)}>
              <div className="tabsnav__title">
                <span className="tabsnav__number">{i + 1}</span>
                  <h2>{category.title}</h2>
                </div>              
              </button>
              <AccordionExtraLayer posts={this.sortByDate(accItems[category.title])} category={category} lang={locale} active={this.state.showPopup} onToggleChange={this.togglePopup}></AccordionExtraLayer>

          </section>
          ))}
        </div>
        {this.state.showPopup ? (
          <div className="Popup-Overlay">
            <div className="Popup-Background" onClick={this.togglePopup}
            ></div>
            <div className="Popup-Inner">
              <button onClick={this.togglePopup}><X className="Popup-Close"/></button>
              {this.state.activeIndex !== -1 ? (
              <Slider items={accItems[this.props.categories[this.state.activeIndex].title]} />
              ) : <Slider items={accItems[this.props.categories[1].title]} />}
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}