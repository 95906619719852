import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout.js'
import Accordion from '../components/Accordion/Accordion1'

import '../components/Accordion/Accordion.css'

export const LandingPageTemplate = ({
  pagetitle,
  gallery,
  works = [],
  workCategories = [],
  pathname,
  locale,
  allPages
}) => (
  <main className="main__content">
    <Accordion defaultActiveKey="3" categories={workCategories} work={works} images={gallery} locale={locale}></Accordion>
  </main>
)

const LandingPage = ({ 
  pageContext: {locale, allPages},
  location: { pathname },
  data: { page, works, workCategories }}) => (
  <Layout
    title={page.frontmatter.pagetitle || false}
    locale={locale}
    path={pathname}
  > 
    <LandingPageTemplate 
      path={pathname}
      locale={locale}
      {...page} 
      {...page.frontmatter} 
      works={works.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
      workCategories={workCategories.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default LandingPage

export const pageQuery = graphql`
  query LandingPage($locale: String!, $id: String!) {
    page: markdownRemark (frontmatter: {locale: {eq: $locale}}, id: {eq: $id}) {
      html
      frontmatter {
        pagetitle
        template
        gallery {
          alt 
          image 
          title 
        }
      }
    }
    works: allMarkdownRemark(
      filter: {fields: {contentType: {eq: "works"}, locale: {eq: $locale}}}
      sort: { order: DESC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            status
            date
            accordion
            featuredImage
            category
          }
        }
      }
    }
    workCategories: allMarkdownRemark(
      filter: {fields: {contentType: {eq: "workCategories"}, locale: {eq: $locale}}}
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }    
  }
`
