import React, {Fragment} from 'react'
import { Link } from 'gatsby'
import _kebabCase from 'lodash/kebabCase'
import _ from 'lodash'
import {ArrowRight} from 'react-feather'

class AccordionInnerContent extends React.Component {
  static defaultProps = {
    category: '',
    posts: [],
    title: '',
    limit: 4,
    perPageLimit: 4
  }

  state = {
    limit: this.props.limit,
    loadMoreTitle: ''
  }
  componentDidMount(){
    this.setState({loadMoreTitle: this.props.category.title})
  }

  increaseLimit = () =>
    this.setState(prevState => ({
      limit: prevState.limit + this.props.perPageLimit
    }))
  handleMouseEnter = (i)=>{
    this.props.mouseEnter(i)
  }

  render() {
    const { posts, category, lang } = this.props,
    { limit, loadMoreTitle } = this.state,
    visiblePosts = posts.slice(0, limit )

    return (
      <Fragment>
        {!!visiblePosts && visiblePosts.map((post, i) => (
          <div className="list-item" key={`list-item-${_kebabCase(post.title) + '-' + i}`}>
            <Link to={`${post.slug}`} className="list-link" onMouseEnter={()=>this.handleMouseEnter(i)}>
              <div className="list-link__container">
                <span className="list-link__txt">{post.title}</span>
                <span className="list-link__icon"><ArrowRight size="15"/></span>
              </div>
                {!!post.subtitle && (
                  <p className="subtxt">{post.subtitle}</p>
                )}
            </Link>
          </div>
        ))}
          <div className="acc taCenter">
            <Link to={`/${lang}/works/${_.camelCase(category.title.replace(' ', ''))}`} className="button circleButton left">
              <span className="txt">{'All '+loadMoreTitle}</span>
              <span className="circle"></span>
              <ArrowRight size='15'/>
            </Link>
          </div>
     </Fragment>
    )
  }
}

export default AccordionInnerContent
